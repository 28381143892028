<template>
  <div>
    <v-card style="padding: 10px">
      <div class="v-card__title">{{ translate("New Checkin") }}</div>
      <div class="v-card__text">
        <form novalidate="novalidate" class="v-form multi-col-validation">
          <div class="row">
            <div class="col-md-6 col-12">
              <label class="text-center text-uppercase text--primary bold">{{
                translate("Date")
              }}</label>

              <div
                class="v-input v-input--hide-details v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder"
              >
                <div class="v-input__control">
                  <div class="v-input__slot">
                    <fieldset aria-hidden="true">
                      <legend style="width: 0px">
                        <span class="notranslate">​</span>
                      </legend>
                    </fieldset>

                    <v-datetime-picker class="outlined" v-model="date" @input="menu2">
                    </v-datetime-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label class="text-center text-uppercase text--primary bold">{{
                translate("Magazine")
              }}</label>

              <v-select
                outlined
                dense
                @input="(name) => magazine_selected(name)"
                :items="magazines.map((a) => a.name)"
              ></v-select>
            </div>
            <div class="col-md-6 col-12">
              <label class="text-center text-uppercase text--primary bold">{{
                translate("Supplier")
              }}</label>

              <v-select
                dense
                outlined
                item-text="text"
                item-value="value"
                @input="(name) => supplier_selected(name)"
                :items="suppliers.map((a) => a.name)"
              ></v-select>
            </div>

            <div></div>
          </div>
        </form>
      </div>

      <v-card class="mx-auto mt-10" outlined>
        <div data-v-6fcca708="" class="d-flex justify-space-between flex-wrap pt-12">
          <div data-v-6fcca708="" class="me-2 mb-2">
            <div data-v-6fcca708="" class="v-card__title pt-0 px-0">
              {{ translate("Received Stocks") }}
              <span
                ><v-autocomplete
                  @change="productSkuChanged"
                  item-value="id"
                  outlined
                  item-text="skuName"
                  :filter="productSkuFilter"
                  :items="products"
                  :label="translate('Search by SKU')"
                ></v-autocomplete>
              </span>
            </div>
          </div>
          <button
            data-v-6fcca708=""
            v-on:click="
              stocks.push({
                image_url: '',
                id: Math.random() * 10000,
                product: products[0],
                amount: 0,
                quantity_price: 0,
                tax: stocks.length == 0 ? 0 : stocks.slice(-1).pop().tax,
                discount: 0,
              })
            "
            type="button"
            class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
          >
            <span class="v-btn__content">{{ translate("add") }}</span>
          </button>
        </div>
        <v-simple-table height="auto" max-height="500" fixed-header :key="key">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center text-uppercase">{{ translate("Image") }}</th>

                <th class="text-center text-uppercase">{{ translate("Product") }}</th>

                <th class="text-center text-uppercase">
                  {{ translate("Quantity Price") }}
                </th>
                <th class="text-center text-uppercase">{{ translate("Amount") }}</th>
                <th class="text-center text-uppercase">{{ translate("Discount") }} %</th>
                <th class="text-center text-uppercase">{{ translate("Total") }}</th>
                <th class="text-center text-uppercase">{{ translate("Actions") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="stock in stocks" :key="stock.id">
                <td class="text-center" style="padding: 10px">
                  <div
                    style="
                      overflow: visible;
                      position: relative;
                      min-width: 110px;
                      max-width: 110px;
                      min-height: 110px;
                      max-height: 110px;
                      margin: 5px;
                      padding: 5px;
                      border-style: dashed;
                    "
                  >
                    <a :href="stock.image_url" target="_blank"
                      ><v-img
                        style="height: 95px; width: 100%"
                        :src="stock.image_url"
                      ></v-img
                    ></a>
                  </div>
                </td>
                <td class="text-center" style="padding: 10px">
                  <v-select
                    dense
                    outlined
                    class="shrink"
                    v-model="stock['product_name']"
                    @input="(id) => product_selected(stock, id)"
                    :items="products.map((a) => a.name)"
                  ></v-select>
                </td>

                <td class="text-center" style="padding: 10px">
                  <v-text-field
                    v-model="stock['quantity_price']"
                    :label="'Price of one ' + stock.product.measurement_name"
                    clear-icon="shrink"
                    :suffix="currency"
                    outlined
                    type="number"
                    dense
                  ></v-text-field>
                </td>
                <td class="text-center" style="padding: 10px">
                  <v-text-field
                    label="Amount"
                    v-model="stock['amount']"
                    :suffix="stock.product.measurement_name"
                    outlined
                    dense
                    type="number"
                  ></v-text-field>
                </td>
                <td class="text-center" style="padding: 10px">
                  <v-text-field
                    label="Discount"
                    v-model="stock['discount']"
                    suffix="%"
                    outlined
                    dense
                    type="number"
                  ></v-text-field>
                </td>

                <td>
                  <label v-if="stock['amount'] == 0 || stock['quantity_price'] == 0"
                    >0 {{ currency }}</label
                  >
                  <label v-else>{{ calculateTotal(stock) }} {{ currency }}</label>
                </td>
                <td class="text-center">
                  <div
                    v-on:click="stocks = stocks.filter((item) => item !== stock)"
                    class="v-avatar elevation-1 rounded error"
                    style="height: 30px; min-width: 30px; width: 30px"
                  >
                    <span
                      aria-hidden="true"
                      class="v-icon notranslate theme--dark white--text"
                      style="font-size: 25px; height: 25px; width: 25px"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        role="img"
                        aria-hidden="true"
                        class="v-icon__svg"
                        style="font-size: 25px; height: 25px; width: 25px"
                      >
                        <path :d="icons.mdiCloseCircleOutline"></path></svg
                    ></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div style="padding-left: 20px">
          <h4 class="text-decoration-none" style="font-weight: 500">
            {{ translate("Sub Total") }}: {{ getSubTotal }} {{ currency }}
          </h4>
          <h4 class="text-decoration-none" style="font-weight: 500">
            {{ translate("Total Discount") }}: {{ getDiscount }} {{ currency }}
          </h4>
          <h4 class="text-decoration-none" style="font-weight: 500">
            {{ translate("Total Tax") }}: {{ getTax }} {{ currency }}
          </h4>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-space-between flex-wrap" style="padding-left: 10px">
          <h3 style="font-weight: 600">
            {{ translate("Grand Total") }}: {{ getGrandTotal }} {{ currency }}
          </h3>
          <v-btn color="success" style="margin: 10px" v-on:click="submit">
            {{ translate("Submit") }}
          </v-btn>
        </div>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import axios from "axios";
import { mdiPlus, mdiCloseCircleOutline } from "@mdi/js";
import {
  getCurrency,
  getMagazines,
  getProducts,
  getSuppliers,
  newCheckin,
} from "@/ApiManager";

export default {
  methods: {
    productSkuFilter(item, queryText, itemText) {
      var response = 0;
      if (item.sku.includes(queryText)) {
        response = 1;
      }
      if (itemText.includes(queryText)) {
        response = 1;
      }
      return response;
    },
    productSkuChanged(product_id) {
      var product = this.products.filter((item) => item.id == product_id)[0];
      this.stocks.push({
        id: Math.random() * 10000,
        product_id: product_id,
        image_url: "",
        product: product,
        amount: 1,
        quantity_price: 0,
        tax: this.stocks.length == 0 ? 0 : this.stocks.slice(-1).pop().tax,
        discount: 0,
        type: "",
      });
      var product_name = this.products.filter((item) => item.id == product_id)[0].name;
      var stock = this.product_selected(
        this.stocks[this.stocks.length - 1],
        product_name
      );
      console.log("stock is", stock);
      this.key += 1;
    },

    turnStockToString(stock) {
      return `${stock.id}${stock.amount}${stock.product.id}${stock.quantity_price}${stock.tax}${stock.discount}`;
    },
    calculateTotal(stock) {
      var price = 0;
      //get total price
      price = stock.amount * stock.quantity_price;
      //calculate discount
      price = price - (price * stock.discount) / 100;
      //calculate tax
      price = price + (price * stock.tax) / 100;
      return price;
    },
    product_selected(stock, name) {
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i]["name"] == name) {
          stock["id"] = Math.random() * 10000;
          stock["product"] = this.products[i];
          stock["product_name"] = this.products[i].name;
          stock["product_id"] = this.products[i].product_id;
          stock["measurement_name"] = this.products[i].measurement_name;
          stock["product_amount"] = 1;
          stock["amount"] = 1;
          stock["quantity_price"] = this.products[i].buy_price;
          stock["image_url"] = this.products[i].image_url;
          console.log(this.stocks);
          return stock;
        }
      }
    },
    supplier_selected: function (supplier_name) {
      for (var i = 0; i < this.suppliers.length; i++) {
        if (this.suppliers[i]["name"] == supplier_name) {
          this.selected_supplier = this.suppliers[i]["id"];
          return;
        }
      }
    },
    magazine_selected: function (magazine_name) {
      for (var i = 0; i < this.magazines.length; i++) {
        if (this.magazines[i]["name"] == magazine_name) {
          this.selected_magazine = this.magazines[i]["id"];
          return;
        }
      }
    },
    new_raw_material: function (_) {
      this.raw_materials.push({ key: this.key, raw_material_id: 0, amount: 0 });
      this.key += 1;
    },
    cancel: function (_) {
      this.$router.go(-1);
    },
    submit: async function (_) {
      this.$store.state.loading = true;
      var date = `${this.date.getFullYear()}/${("0" + (this.date.getMonth() + 1)).slice(
        -2
      )}/${("0" + this.date.getDate()).slice(
        -2
      )} ${this.date.getHours()}:${this.date.getMinutes()}:${this.date.getSeconds()}`;
      await newCheckin(
        parseInt(this.selected_magazine),
        this.supplier_is_paid,
        this.selected_supplier,
        this.getGrandTotal,
        date,
        JSON.stringify(this.stocks)
      );
      this.$router.push({ name: "stocks" });
    },
  },
  computed: {
    computedStocks() {
      var key = this.key;
      if (key == 0) {
        //this is just to update the computed property when the key changes
      }
      const stocks = JSON.parse(JSON.stringify(this.stocks));
      return stocks;
    },
    getSubTotal() {
      var price = 0;
      for (var i = 0; i < this.stocks.length; i++) {
        price += this.stocks[i].quantity_price * this.stocks[i].amount;
      }
      return price;
    },
    getDiscount() {
      var price = 0;
      for (var i = 0; i < this.stocks.length; i++) {
        var cost = this.stocks[i].quantity_price * this.stocks[i].amount;
        price += (cost * this.stocks[i].discount) / 100;
      }
      return price;
    },
    getTax() {
      var price = 0;
      for (var i = 0; i < this.stocks.length; i++) {
        var cost = this.stocks[i].quantity_price * this.stocks[i].amount;
        cost -= (cost * this.stocks[i].discount) / 100;
        price += (cost * this.stocks[i].tax) / 100;
      }
      return price;
    },
    getGrandTotal() {
      var price = 0;
      for (var i = 0; i < this.stocks.length; i++) {
        var stock = this.stocks[i];
        //get total price
        var cost = stock.amount * stock.quantity_price;
        //calculate discount
        cost = cost - (cost * stock.discount) / 100;
        //calculate tax
        cost = cost + (cost * stock.tax) / 100;
        price += cost;
      }
      return price;
    },
  },
  created() {
    getProducts().then((response) => {
      this.products = response.filter((item) => item.type == "single");
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i].sku != undefined) {
          this.products[i].skuName = `${this.products[i].name} - ${this.products[i].sku}`;
        }
      }
      console.log(this.products);
    });
    getMagazines().then((response) => {
      for (var i = 0; i < response.length; i++) {
        var product = {
          id: response[i]["id"],
          name: response[i]["name"],
        };
        this.magazines.push(product);
      }
    });
    getCurrency().then((response) => {
      this.currency = response["currency"];
    });
    getSuppliers().then((response) => {
      for (var i = 0; i < response.length; i++) {
        var product = {
          id: response[i]["id"],
          name: response[i]["name"],
        };
        this.suppliers.push(product);
      }
    });
  },
  data() {
    return {
      computedStockKey: 0,
      supplier_is_paid: true,
      date: new Date(),
      menu2: false,
      products: [],
      //this is the available raw material that the user can choose from
      magazines: [],
      //this list contains the raw materials that the user have chosen
      suppliers: [],
      selected_supplier: 0,
      selected_magazine: 0,
      stocks: [],
      currency: "",
      key: 0,
    };
  },
  setup() {
    const product_name = ref("");
    const leaflet_key = ref("");
    const inventory_address = ref("");
    const sell_price = ref("");

    return {
      icons: {
        mdiPlus,
        mdiCloseCircleOutline,
      },
      product_name,
      leaflet_key,
      inventory_address,
      sell_price,
    };
  },
};
</script>
<style scoped>
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");

.bold {
  color: black;
  font-family: sans-serif;
  font-weight: 700;
}
.component-container {
  display: table;
  @media (pointer: none), (pointer: coarse) {
    width: calc(100% - (50%));
  }
}
.component-row {
  display: table-row;
}
.component-row div {
  position: relative;
  display: table-cell;
}
.component-row div:nth-child(2) {
  left: 20px;
}
.component-row div:nth-child(3) {
  left: 40px;
}
</style>
